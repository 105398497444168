<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>活动管理</el-breadcrumb-item>
                    <el-breadcrumb-item>拼手气红包</el-breadcrumb-item>
                    <el-breadcrumb-item>配置列表</el-breadcrumb-item>
                    <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <el-form label-width="150px" ref="form" :model="form">

            <div class="admin_main_block admin_m15">
                <div class="admin_main_block_top">
                    <div class="admin_main_block_right">
                        <el-row>
                            <el-col :span="8">
                            </el-col>
                            <el-col :span="8">
                                <el-button type="primary" @click="success('form')">确认</el-button>
                            </el-col>
                            <el-col :span="8">
                                <el-button icon="el-icon-back" @click="$router.go(-1)">返回</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <div class="admin_form_main">
                    <el-form-item label="设置日期：" prop="date"
                                  :rules="[{ required: true, message: '请选择日期', trigger: 'blur' },]">
                        <el-date-picker
                                v-model="form.date"
                                type="date"
                                value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd"
                                placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="昨⽇⼴告收入：" prop="uniAdvertiseAmount"
                                  :rules="[{ required: true, message: '请填写昨⽇⼴告收入', trigger: 'blur' },]">
                        <el-input-number type="text" placeholder="" :min="0" :max="1000000" :step="100"
                                         :disabled="disabled"
                                         v-model="form.uniAdvertiseAmount"></el-input-number>
                    </el-form-item>
                    <el-form-item label="昨⽇⽇活量：" prop="dayNums"
                                  :rules="[{ required: true, message: '请填写昨⽇⽇活', trigger: 'blur' },]">
                        <el-input-number type="text" placeholder="" :min="0" :max="10000000" :step="100"
                                         :disabled="disabled"
                                         v-model="form.dayNums"></el-input-number>
                    </el-form-item>
                    <el-form-item label="每人领取个数：" prop="dayMaxAdvertiesNums"
                                  :rules="[{ required: true, message: '请填每人领取个数', trigger: 'blur' },]">
                        <el-input-number type="text" placeholder="" :min="1" :max="1000" :step="10"
                                         :disabled="disabled"
                                         v-model="form.dayMaxAdvertiesNums"></el-input-number>
                    </el-form-item>
                    <el-form-item label="最小金额：" prop="minMoney"
                                  :rules="[{ required: true, message: '请填写最小金额', trigger: 'blur' },]">
                        <el-input-number type="text" placeholder="" :min="0" :max="10000" :step="0.01" :precision="4"
                                         :disabled="disabled"
                                         v-model="form.minMoney"></el-input-number>
                    </el-form-item>
                    <el-form-item label="最大金额：" prop="maxMoney"
                                  :rules="[{ required: true, message: '请填写最大金额', trigger: 'blur' },]">
                        <el-input-number type="text" placeholder="" :min="form.minMoney" :max="10000000" :step="1" :precision="4"
                                         :disabled="disabled"
                                         v-model="form.maxMoney"></el-input-number>
                    </el-form-item>
                    <el-form-item label="红包⾦额概率：">
                        <el-row class="header">
                            <el-col :span="span6">用户等级</el-col>
                            <el-col :span="span6" v-for="(item,key) in header" :key="key">{{item.name}}</el-col>
                        </el-row>
                        <el-row v-for="(item) in form.vip" :key="item.name">
                            <el-col :span="span6">{{item.name}}</el-col>
                            <el-col :span="span6" v-for="(rs,index) in item.list" :key="index">
                                <el-input-number type="text" v-model="item.list[index]" placeholder="" :min="0" :max="100" :step="1"
                                                 ></el-input-number>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </div>
            </div>
        </el-form>
    </div>
</template>
<script>
    export default {
        components: {},
        data() {
            return {
                span6: 4,
                title:'新增',
                header:[
                    {name:'0.001元概率',sum:0},
                    {name:'0.002~0.999元概率',sum:0},
                    {name:'1~4.999元概率',sum:0},
                    {name:'5~9.999元概率',sum:0},
                    {name:'10元概率',sum:0},
                ],
                disabled:false,
                form: {
                    id:'',
                    date:'',
                    uniAdvertiseAmount:'',
                    dayNums:'',
                    dayMaxAdvertiesNums:'',
                    minMoney:0.01,
                    maxMoney:'',
                    vip:[
                        {name:'VIP4',level:4,list:[14,58,15,7,6]},
                        {name:'VIP3',level:3,list:[31,52,11,3,3]},
                        {name:'VIP2',level:2,list:[55,35,8,1,1]},
                        {name:'VIP1',level:1,list:[70,23,6,1,0]},
                        {name:'普通',level:0,list:[83,17,0,0,0]},
                    ],
                },
            }
        },
        created() {
            this.form.id = this.$route.query.id;
            if (this.form && this.form.id) {
                this.title = "编辑";
                this.infoData();
            }
        },
        methods: {
            success(formName) {
                console.log(JSON.stringify(this.form));
                let msgRowData = "";
                let msgColData = "";
                let header = [
                    {name:'0.001元概率',sum:0},
                    {name:'0.002~0.999元概率',sum:0},
                    {name:'1~4.999元概率',sum:0},
                    {name:'5~9.999元概率',sum:0},
                    {name:'10元概率',sum:0},
                ];
                let col = [
                    {name:'VIP4',sum:0},
                    {name:'VIP3',sum:0},
                    {name:'VIP2',sum:0},
                    {name:'VIP1',sum:0},
                    {name:'普通',sum:0},
                ];

                this.form.vip.forEach((item,index) => {
                    header[0].sum += item.list[0];
                    header[1].sum += item.list[1];
                    header[2].sum += item.list[2];
                    header[3].sum += item.list[3];
                    header[4].sum += item.list[4];
                    col[index].sum = 0;
                    col[index].sum = eval(item.list.join('+'));
                });
                if (header) {
                    header.map((item) => {
                        if(item.sum !== 100){
                            msgRowData = item;
                        }
                    });

                }
                if (col) {
                    col.map((item) => {
                        if(item.sum !== 100){
                            msgColData = item;
                        }
                    });

                }
                // console.log('row',JSON.stringify(header));
                // console.log('col',JSON.stringify(col));
                if(msgRowData){
                    // this.$message({type: "warning", message: msgRowData.name+'总和概率大于100%'});
                    // return false;
                }
                if(msgColData){
                    this.$message({type: "warning", message: msgColData.name+'总和概率大于或小于100%'});
                    return false;
                }
                this.$confirm("是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.submitForm(formName);
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消",
                        });
                    });
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let url = this.$api.activityLuckyPacketsCreateRedTask;
                        console.log("submitForm", this.form)
                        if (this.form.id) {
                            url = this.$api.activityLuckyPacketsUpdateRedTask;
                        }
                        this.$post(
                            url,
                            this.form
                        ).then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    type: "success",
                                    message: "成功",
                                });
                                this.$router.push({name: 'luckyPacketsConfigList'});
                            } else {
                                this.$message({
                                    type: "info",
                                    message: res.msg,
                                });
                            }
                        });
                    } else {
                        this.$message({
                            type: "warning",
                            message: "请填写完整信息！",
                        });
                        return false;
                    }
                });
            },
            infoData(){
                this.$get(this.$api.activityLuckyPacketsInfoRedTask+this.form.id).then((res) => {
                    if (res.code === 1) {
                        this.form = res.data;
                    }
                });
            },
        },
    }
</script>
<style lang="scss" scoped>
    .admin_main_block_right {
        width: 230px;
    }
    .admin_form_main .el-form-item {
        width: 1000px;
    }
    .el-col {
        margin: 3px 0;
        text-align: center;
    }
    .header {
        .el-col {
            color: #6d6b6b;
            font-weight: bold;
        }
    }
</style>
